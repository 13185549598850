@import 'variables';

@import 'bootstrap/scss/bootstrap';
@import 'react-image-gallery/styles/scss/image-gallery';
@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';
@import 'react-image-lightbox/style';

@import 'mixins';
@import 'grids';
@import 'buttons';
@import 'covers';
@import 'testimonials';
@import 'gallery';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Font Family
.font-family-arial {
  font-family: Arial;
}

.font-family-serif {
  font-family: $font-family-serif;
}

.font-family-open-sans {
  font-family: Roboto;
}

.font-family-cabin {
  font-family: Roboto;
}

.font-size-base {
  @include font-size($font-size-base);
}

// Font Size
.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 17px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-48 {
  font-size: 48px;
}

// Font Weight
.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

// Border Radius
.rounded-md {
  border-radius: 5px !important;
}

.rounded-md-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-md-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-md-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-md-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-lg {
  border-radius: 10px !important;
}

.rounded-lg-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.rounded-lg-right {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.rounded-lg-bottom {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.rounded-lg-left {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

// Background Color
.bg-color-transparent {
  background-color: transparent !important;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.nav-anchor {
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  color: $body-color;
  font-weight: 500;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &.active {
    color: $blue;
  }
}

.view-more {
  display: block;
  width: 100%;
  background: $white;
  border: 1px solid $blue;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

.mega-shadow {
  // box-shadow: 0 9px 24px 0 rgba(0, 5, 9, 0.33);
  // box-shadow: 0 9px 24px 0 #e3e3e4;
  box-shadow: 0 9px 24px 0 rgba(0, 5, 9, 0.33);
}

.line-height-md {
  line-height: 1.7;
}

.line-height-lg {
  line-height: 2;
}

.text-shadow {
  text-shadow: 0 2px 5px rgba(0, 24, 41, 0.5), 0 50px 75px #001829;
}

.inversed-link {
  color: $body-color;

  @include hover() {
    color: $blue;
  }

  &-raven {
    color: $raven;
  }

  &-raven-success {
    color: $raven;

    @include hover() {
      color: $green-haze;
    }
  }

  &-trout {
    color: $raven;
  }

  &-gray-chateau {
    color: $gray-chateau;
  }
}

.aspect-50 {
  height: 0;
  padding-bottom: 50%;
}

.aspect-65 {
  height: 0;
  padding-bottom: 65%;
}

.ghost-content {
  font-size: 120%;
  line-height: 2;

  img {
    max-width: 100%;
    height: auto;
  }
}

.dropdown-menu {
  box-shadow: 0 9px 24px 0 rgba(0, 0, 0, 0.1);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.op-50 {
  opacity: 0.5;
}

.op-90 {
  opacity: 0.9;
}

.legal {
  text-align: justify;

  p {
    margin-bottom: 50px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 40px;
    font-weight: bold;
  }

  ul,
  ol {
    margin-bottom: 40px;
    padding-left: 120px;

    li {
      padding: 15px 0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}

.truncate {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.scroller {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-outline,
.no-outline:focus {
  outline: none;
  box-shadow: none !important;
}

.property-star {
  position: absolute;
  top: 5px;
  left: 7px;
  border: 0;
  z-index: 5;
  outline: none;
}

.property-name {
  max-height: 70px;
  overflow: hidden;
}

.property-cover {
  width: 100%;
}

.button-promote {
  background-color: $promote-green;

  color: $promote-green;
  @extend .font-weight-bold;

  &_header {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgba($promote-green, .2);
    border-radius: 30px;

    &:hover,
    &:focus {
      color: $promote-green;
      text-decoration: none;
    }
  }

  &_mobile_searchform {
    background-color: rgba($promote-green, .2);
    border-radius: 30px;
    display: block;
    color: $promote-green;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover,
    &:focus {
      color: $promote-green;
      text-decoration: none;
    }
  }

  &_mobile_hero {
    border-radius: .3rem;
    display: block;
    color: $white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }

  }
}

.certificate {
  &-mobile {
    background-color: $blue;
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
    font-size: 16px;
    @extend .font-weight-bold;
    color: $white;
    text-align: center;
  }

  &-desktop {
    padding: 1rem 1.5rem 1rem 1rem;
    border: 1px solid $certificate-orange;
    display: flex;
    position: absolute;
    bottom: 1.25rem;
    right: 1.25rem;
    font-size: 12px;
    font-weight: 500;
    max-width: 18rem;
    border-radius: 10rem;
    align-items: center;
    background-color: $white;
  }
}

.text-certificate-orange {
  color: $certificate-orange !important;
}

.property-promotion {
  position: absolute;
  z-index: 5;
  white-space: nowrap;
  padding: 10px 150px;
  font-weight: 500;
  transform: rotate(315deg);
  top: 60px;
  left: -110px;
  background-color: $neon-carrot;
}

.propertycard {
  overflow: hidden;
}

.propertycard .property-promotion {
  font-size: 10px;
  top: 25px;
  left: -150px;
  padding-top: 4px;
  padding-bottom: 4px;
}


@import 'print';