// @mixin button-variant(
//   $background,
//   $border,
//   $hover-background: darken($background, 7.5%),
//   $hover-border: darken($border, 10%),
//   $active-background: darken($background, 10%),
//   $active-border: darken($border, 12.5%)
// )

// @mixin button-outline-variant(
//   $color,
//   $color-hover: color-yiq($color),
//   $active-background: $color,
//   $active-border: $color
// )

.btn-outline-dimmed {
  @include button-outline-variant($gray-chateau, $raven, #f2f3f5, #f2f3f5);
  border-color: $ghost;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    color: $raven;
  }
}

.btn-white {
  @include button-variant($white, transparent, $white);
  color: $blue;

  @include hover() {
    color: $blue;
  }
}

.btn-tab {
  color: $raven;
  font-weight: 500;
  border-radius: 50rem;
  padding-left: 20px;
  padding-right: 20px;

  @include hover() {
    color: $blue;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    $color: $raven;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    color: $white;
    background-color: $blue;
  }
}