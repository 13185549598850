.modal-content.testimonial {
  box-shadow: 0 9px 24px 0 #cde7f0;
}

.modal-backdrop.testimonial {
  opacity: 0.8;
  background-color: $foam;
}

.testimonial-close {
  position: absolute;
  top: 25px;
  right: 25px;
}
