/**
 * The asterisk targets all HTML elements in a web page.
 */
.print--only {
    display: none;
}

@media print {
    * {
        -webkit-transition: none !important;
        transition: none !important;
        -webkit-print-color-adjust: exact !important;
        /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;
        /* Firefox 48 – 96 */
        print-color-adjust: exact !important;
        /* Firefox 97+, Safari 15.4+ */
        // page-break-inside: avoid;
    }

    .print-- {
        &only {
            display: inline-block !important;
        }

        &margin-bottom {
            margin-bottom: 24px;
        }

        &none {
            display: none !important;
            visibility: hidden !important;
        }

        // &page-break {
        //     page-break-before: always;
        // }

        &header {
            form {
                display: none;
            }

            .d-flex {
                div {
                    display: none !important;
                }
            }

            a {
                width: 100%;
            }

            border-bottom: none !important;
        }

        &check {
            background-color: #ea2470;
            border: 1px solid blue;
        }

        &gallery {

            &-main,
            &-secondary,
            &-thirdary {
                width: 100%;
                border-radius: 5px;
            }

            &-thirdary {
                margin-top: 20px;
            }
        }

        &title {
            div div {
                display: none;

            }

            div {
                padding-bottom: 0;

            }

            h1 {
                margin-bottom: 0;
            }
        }

        &extract {

            h1,
            h3,
            h5 {
                display: none;
            }
        }

        &price {
            text-align: right;
        }



        &area {
            .tab-active-measurements {
                display: none;
            }

            .print--wrap-measurements {
                display: none !important;
            }
        }

        &measurements {
            .tab-active-area {
                display: none;
            }

            .print--wrap-area {
                display: none !important;
            }

            .tab-active-measurements {
                color: #ffffff;
                background-color: #0078c9;
            }
        }

        &all-images {
            margin: 25px;
            width: 50%;
            max-width: 50%;
            min-width: 50%;
            border-radius: 5px;
        }


    }

    .googlemap-wrapper {

        h4,
        h6 {
            display: none;
        }

        div {
            max-height: 325px !important;
        }
    }
}