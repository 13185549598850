$ig-blue: #0078c9;

.image-gallery-slides {
  border-radius: 10px;
}

.image-gallery-thumbnail {
  border-radius: 10px;
}

.image-gallery-thumbnail-inner {
  border-radius: 5px;
  overflow: hidden;
}

.image-gallery {
  &.fullscreen-modal {
    z-index: 1020;
    background: rgba(6, 17, 27, 0.9);
  }
}

.image-gallery-content {
  &.fullscreen {
    background: transparent;

    .image-gallery-description {
      display: block;
    }
  }
}

.image-gallery-slide {
  outline: none;

  .image-gallery-description {
    display: none;
    position: relative;
    background: none;
    bottom: 0;
    font-size: 18px;
    padding-top: 0px;
  }
}

.image-gallery-fullscreen-button {
  top: 0;
  bottom: auto;
}