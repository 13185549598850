$font-family-sans-serif: Roboto;
$font-family-serif: Roboto;

$white: #ffffff;
$black: #000000;
$blue: #0078c9;
$red: #f9464e;
$green: #37bf75;
$orange: #f38b46;
$yellow: #ffdf85;
$body-color: #22262a;
$foam: #f0fafe;
$blue-zodiac: #123150;
$blue-zodiac-95: rgba($blue-zodiac, 0.95);
$green-haze: #00a25a;
$porcelain: #f7f8f9;
$gray-chateau: #a2aab4;
$raven: #757d88;
$malachite: #0fb04b;
$trout: #505862;
$ziggurat: #b8d7e2;
$athens-gray: #f8f9fa;
$athens-gray-2: #e5e8ec;
$lily-white: #e7f8ff;
$regal-blue: #00446d;
$regal-blue-50: rgba($blue-zodiac, 0.5);
$anakiwa: #a8dcff;
$daintree: #00263d;
$daintree-50: rgba($daintree, 0.5);
$ghost: #c5ccd5;
$malibu: #6bc0f9;
$neon-carrot: #ff9637;
$carise-red: #df226b;
$promote-green: #47A061;
$certificate-orange: #EC7100;

$custom-select-bg-size: 10px 6px;
$custom-select-indicator: url('/images/triangle-down.png');

$dropdown-border-radius: 5px;
$custom-select-box-shadow: none;

// $border-radius-lg: 10px;

$dropdown-spacer: 8px;
$dropdown-border-width: 0;
$dropdown-box-shadow: 0 9px 24px 0 #e3e3e4;

$modal-content-border-width: 0;
$modal-content-border-radius: 10px;

$input-placeholder-color: #c5ccd5;
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;

$theme-colors: (
  'black': $black,
  'foam': $foam,
  'blue-zodiac': $blue-zodiac,
  'green-haze': $green-haze,
  'porcelain': $porcelain,
  'gray-chateau': $gray-chateau,
  'raven': $raven,
  'malachite': $malachite,
  'trout': $trout,
  'ziggurat': $ziggurat,
  'athens-gray': $athens-gray,
  'athens-gray-2': $athens-gray-2,
  'lily-white': $lily-white,
  'regal-blue': $regal-blue,
  'anakiwa': $anakiwa,
  'blue-zodiac-95': $blue-zodiac-95,
  'regal-blue-50': $regal-blue-50,
  'daintree': $daintree,
  'daintree-50': $daintree-50,
  'ghost': $ghost,
  'malibu': $malibu,
  'neon-carrot': $neon-carrot,
  'carise-red': $carise-red,
);

$grid-breakpoints: (
  xs: 0,
  sm: 706px,
  md: 898px,
  lg: 1122px,
  xl: 1330px,
);

$container-max-widths: (
  sm: 670px,
  md: 850px,
  lg: 1090px,
  xl: 1270px,
);

$spacers: (
  1px: 1px,
  2px: 2px,
  3px: 3px,
  4px: 4px,
  5px: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
  11: 11px,
  12: 12px,
  13: 13px,
  15: 15px,
  16: 16px,
  17: 17px,
  20: 20px,
  23: 23px,
  24: 24px,
  25: 25px,
  30: 30px,
  35: 35px,
  32: 32px,
  33: 33px,
  40: 40px,
  45: 45px,
  48: 48px,
  50: 50px,
  55: 55px,
  60: 60px,
  65: 65px,
  70: 70px,
  75: 75px,
  80: 80px,
  85: 85px,
  90: 90px,
  95: 95px,
  100: 100px,
  110: 110px,
  120: 120px,
  130: 130px,
  160: 160px,
  185: 185px,
  235: 235px,
  240: 240px,
  320: 320px,
);

$sizes: (
  20px: 20px,
  25px: 25px,
  50px: 50px,
  55px: 55px,
  60px: 60px,
  70px: 70px,
  75px: 75px,
  85px: 85px,
  125px: 125px,
  150px: 150px,
  160px: 160px,
  175px: 175px,
  200px: 200px,
  230px: 230px,
  250px: 250px,
  255px: 255px,
  280px: 280px,
  330px: 330px,
  380px: 380px,
  410px: 410px,
  435px: 435px,
  520px: 520px,
  580px: 580px,
);