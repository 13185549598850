$five-grid-name: 'five';
$five-grid-columns: 5;
$five-grid-gutter-width: 20px;

.#{$five-grid-name}-row {
  @include make-row($five-grid-gutter-width);
}

@include make-named-grid-columns($five-grid-name, $five-grid-columns, $five-grid-gutter-width);

$five-big-grid-name: 'five-big';
$five-big-grid-columns: 5;
$five-big-grid-gutter-width: 55px;

.#{$five-big-grid-name}-row {
  @include make-row($five-big-grid-gutter-width);
}

@include make-named-grid-columns($five-big-grid-name, $five-big-grid-columns, $five-big-grid-gutter-width);
